<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <v-card outlined class="pt-2 pb-3 mb-3">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="6" md="3">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-layers"
                                    clearable
                                    solo
                                    v-model="coil_origin"
                                    :items="coil_origins"
                                    item-value="origin_id"
                                    item-text="descr"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    solo
                                    v-model="commodity"
                                    :items="commodities"
                                    item-value="commodities"
                                    item-text="descr"
                                    label="Crc Commodity"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    solo
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    :item-text="item => $store.getters.convertToCurrency(item.thick)"
                                    label="Thick"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                ></v-autocomplete>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    solo
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    :item-text="item => $store.getters.convertToCurrency(item.width)"
                                    label="Width"
                                    dense
                                    class="ma-0 border-12" 
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                             <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn color="info" elevation="2" @click="search()" style="padding: 19px;" class="border-12"><v-icon class="mr-1">mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="5" md="2">
                            <v-btn
                                class="mr-2 rounded-l mt-1"
                                color="error"
                                elevation="2"
                                large
                                @click="search()"
                            >
                                <v-icon dark>
                                    mdi-magnify
                                </v-icon>
                                Search
                            </v-btn>
                        </v-col>          -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="elevation-12 rounded-l" outlined>
                    <v-card-title class="text-h6">
                        <v-chip class="primary text-white p-4">
                            Total Wgt OH : {{ result ? (result.length > 0 ? $store.getters.convertToCurrency(result[0].total_wgt_oh) : '' ): ''}} Ton
                        </v-chip>

                        <v-chip class="primary text-white p-4 ml-5">
                            Total Qty OH : {{ result ? (result.length > 0 ? $store.getters.convertToCurrency(result[0].total_qty_oh) : '' ): ''}}
                        </v-chip>

                        <v-chip class="primary text-white p-4 ml-5">
                            Total Wgt Plan: {{ result ? (result.length > 0 ? $store.getters.convertToCurrency(result[0].total_wgt_plan) : '' ): ''}} Ton
                        </v-chip>

                        <v-chip class="primary text-white p-4 ml-5">
                            Total Qty Plan : {{ result ? (result.length > 0 ? $store.getters.convertToCurrency(result[0].total_qty_plan) : '' ): ''}}
                        </v-chip>

                        <v-chip class="primary text-white p-4 ml-5">
                            Total Outstandings : {{ result ? (result.length > 0 ? $store.getters.convertToCurrency(result[0].total_out_po) : '' ): ''}} Ton
                        </v-chip>
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-data-table
                            :headers="headers"
                            :items="coils"
                            :options.sync="options"
                            :loading="loading"
                            loading-text="Please wait, retrieving data"
                            :server-items-length="totalItems"
                            :page.sync="pagination"
                            :search="searchItem"
                            page-count="10"
                            fixed-header
                            height="450"
                        >
                            <template v-slot:top>
                                <v-text-field solo v-model="searchItem" label="Search Prod Code... " class="mx-4 border-12" @keyup.enter="search()" style="max-width: 300px;"></v-text-field>
                            </template>
                            <template v-slot:[`item.thick`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.thick) }}
                            </template>
                            <template v-slot:[`item.width`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.width) }}
                            </template>
                            <template v-slot:[`item.wgt_oh`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_oh) }}
                            </template>
                             <template v-slot:[`item.qty_oh`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.qty_oh) }}
                            </template>
                             <template v-slot:[`item.wgt_plan`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_plan) }}
                            </template>
                             <template v-slot:[`item.qty_plan`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.qty_plan) }}
                            </template>
                             <template v-slot:[`item.out_po`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.out_po) }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  export default {
    data() {
        return {
            detail: false,
            start_period: '',
            end_period: '',
            menu: false,
            modal: false,
            loading: false,
            commodities: [],
            commodity: '',
            coat_masss: [],
            coat_mass: '',
            grades: [],
            grade: '',
            thickness: [],
            thick: '',
            widths: [],
            width: '',
            colours: [],
            colour: '',
            qualities: [],
            quality: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Production Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'CRC Availability',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            machines: [],
            machine: '',
            coil_origins: [],
            coil_origin: '',
            options: {},
            headers: [
                { text: 'Coil Origin', value: 'origin' },
                { text: 'Commodity', value: 'commodity' },
                { text: 'Product', value: 'prod_descr' },
                { text: 'Thick', value: 'thick' },
                { text: 'Width', value: 'width' },
                { text: 'Wgt OH', value: 'wgt_oh' },
                { text: 'Qty OH', value: 'wgt_oh' },
                { text: 'Wgt Plan', value: 'wgt_plan' },
                { text: 'Qty Plan', value: 'qty_plan' },
                { text: 'Out PO', value: 'out_po' }
            ],
            coils: [],
            totalItems: 10,
            pagination: 1,
            searchItem: '',
            result: null,
            detail: {},
            dialog: false,
            header_coil: [
                { text: 'Mill ID', value: 'mill_id' },
                { text: 'Coil ID', value: 'coil_id' },
                { text: 'Prod Code', value: 'prod_code' },
                { text: 'Descr', value: 'descr' },
                { text: 'Weight', value: 'wgt' },
                { text: 'Stat', value: 'stat' }
            ],
            detail_coil: [],
            option_coil: {},
            totalItem_coil: 10,
            pagination_coil: 1
        }
    },
    mounted(){
        this.getCoilOrigin()
        this.getCrcCommodity()
        this.getThick()
        this.getWidth()
    },
    methods:{
        close(){
            this.dialog = false
        },
        async getCoilOrigin(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/coilOrigin`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.coil_origins = res.data
            });
        },
        async getCrcCommodity(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/crcCommodity`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.commodities = res.data
            });
        },
        async getThick(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/crcThicks`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.thickness = res.data
            });
        },
        async getWidth(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/crcWidth`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.widths = res.data
            });
        },
        async search(page = 1, itemsPerPage = 10){
            this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/production_report/crc-avail?origin=${this.coil_origin ? this.coil_origin : ''}&commodity=${this.commodity ? this.commodity : ''}&thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&search=${this.searchItem ? this.searchItem : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.result = res.data.result
                this.loading = false
                this.coils = res.data.data.data
                this.totalItems = res.data.data.total
                this.pagination = res.data.data.current_page
            })
        },
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                this.search(page, itemsPerPage)
            },
        deep: true
        }
    }
  }
</script>